<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title"></base-breadcrumb>
    <base-card heading="Importar Avaliação">
      <form-exam-import></form-exam-import>
    </base-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    page: {
      title: 'Importar Avaliação'
    }
  })
}
</script>
